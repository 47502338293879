const getWidthByText = (string, iconSize = [30, 24, 34], padding = 0) => {
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.font = "Source Han Sans";
      text.style.fontSize = 13 + "px";
      text.innerHTML = string + '標準値';
      const width = text.clientWidth + padding;
      document.body.removeChild(text);
      return (width + 12) < 90 ? 90 : width + 12
      return width + iconSize.reduce((a, b) => a + b, 0); // width of button in header
  };

  export { getWidthByText };
